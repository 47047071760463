:root {
  --swal-orange: #FFFFFF;
}

.icon {
  width: 80px;
  height: 80px;
  border-width: 4px;
  border-style: solid;
  border-radius: 50%;
  padding: 0;
  position: relative;
  box-sizing: content-box;
  margin: 20px auto;

  &:first-child {
    margin-top: 32px;
  }
}

.warning {
  border-color: var(--swal-orange);
  animation: pulseWarning 0.75s infinite alternate;
}

.warning_body {
  @extend .warning;

  position: absolute;
  width: 5px;
  height: 47px;
  left: 50%;
  top: 10px;
  border-radius: 2px;
  margin-left: -2px;
  background-color: var(--swal-orange);

}

.warning_dot {
  @extend .warning;

  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: -3px;
  left: 50%;
  bottom: -11px;
  background-color: var(--swal-orange);

}

@keyframes pulseWarning {
  from {
    border-color: #F8D486;
  }
  to {
    border-color: var(--swal-orange);
  }
}

