:root {
  --swal-red: #FFFFFF;
}

.error_icon {
  animation: animateErrorIcon 0.5s;
  width: 80px;
  height: 80px;
  border-width: 4px;
  border-style: solid;
  border-radius: 50%;
  padding: 0;
  position: relative;
  box-sizing: content-box;
  margin: 20px auto;

  &:first-child {
    margin-top: 32px;
  }
}

.error_x_mark {
  border-color: var(--swal-red);
  position: relative;
  display: block;
  animation: animateXMark 0.5s;
}

.error_line_left {
  border-color: var(--swal-red);
  animation: animateErrorIcon 0.5s;
  position: absolute;
  height: 5px;
  width: 47px;
  background-color: var(--swal-red);
  display: block;
  top: 37px;
  border-radius: 2px;
  transform: rotate(45deg);
  left: 17px;
}

.error_line_right {
  border-color: var(--swal-red);
  animation: animateErrorIcon 0.5s;
  position: absolute;
  height: 5px;
  width: 47px;
  background-color: var(--swal-red);
  display: block;
  top: 37px;
  border-radius: 2px;
  transform: rotate(-45deg);
  right: 16px;
}

@keyframes animateErrorIcon {
  from {
    transform: rotateX(100deg);
    opacity: 0;
  }
  to {
    transform: rotateX(0deg);
    opacity: 1;
  }
}

@keyframes animateXMark {
  0% {
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  50% {
    transform: scale(0.4);
    margin-top: 26px;
    opacity: 0;
  }
  80% {
    transform: scale(1.15);
    margin-top: -6px;
  }
  100% {
    transform: scale(1);
    margin-top: 0;
    opacity: 1;
  }
}

