:root {
  --swal-green: #FFFFFF;
  --swal-green-light: rgba(255, 255, 255, 0.2);
}

.icon {
  width: 80px;
  height: 80px;
  border-width: 4px;
  border-style: solid;
  border-radius: 50%;
  padding: 0;
  position: relative;
  box-sizing: content-box;
  margin: 20px auto;

  &:first-child {
    margin-top: 32px;
  }
}

.success {
  border-color: var(--swal-green);
}

.line {
  @extend .success;
  height: 5px;
  background-color: var(--swal-green);
  display: block;
  border-radius: 2px;
  position: absolute;
  z-index: 2;
}

.success_line_long {
  @extend .line;
  width: 47px;
  right: 8px;
  top: 38px;
  transform: rotate(-45deg);
  animation: animateSuccessLong 0.75s;
}

.success_line_tip {
  @extend .line;
  height: 5px;
  width: 25px;
  left: 14px;
  top: 46px;
  transform: rotate(45deg);
  animation: animateSuccessTip 0.75s;
}

.success_ring {
  @extend .success;
  width: 80px;
  height: 80px;
  border: 4px solid var(--swal-green-light);
  border-radius: 50%;
  box-sizing: content-box;
  position: absolute;
  left: -4px;
  top: -4px;
  z-index: 2;
}

@keyframes rotatePlaceholder {
  0% {
    transform: rotate(-45deg);
  }
  5% {
    transform: rotate(-45deg);
  }
  12% {
    transform: rotate(-405deg);
  }
  100% {
    transform: rotate(-405deg);
  }
}

@keyframes animateSuccessTip {
  0% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  54% {
    width: 0;
    left: 1px;
    top: 19px;
  }
  70% {
    width: 50px;
    left: -8px;
    top: 37px;
  }
  84% {
    width: 17px;
    left: 21px;
    top: 48px;
  }
  100% {
    width: 25px;
    left: 14px;
    top: 45px;
  }
}

@keyframes animateSuccessLong {
  0% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  65% {
    width: 0;
    right: 46px;
    top: 54px;
  }
  84% {
    width: 55px;
    right: 0px;
    top: 35px;
  }
  100% {
    width: 47px;
    right: 8px;
    top: 38px;
  }
}

