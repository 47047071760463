/*noinspection CssUnknownTarget*/
@import url('https://fonts.googleapis.com/css?family=Indie+Flower|Roboto');

body {
    margin: 0;
    padding: 0;
    /*font-family: 'Open Sans', sans-serif;*/
}

/*html {*/
/*font-size: 62.5% !important; !* 62.5% of 16px = 10px *!*/
/*}*/
