.Toastify__toast--default {
    background: #fff;
    color: #aaa;
}

.Toastify__toast--info {
    background: #3498db;
}

.Toastify__toast--success {
    background: #f6f6f6;
    color: #07bc0c;
    border: solid #07bc0c;
}

.Toastify__toast--warning {
    background: #f1c40f;
}

.Toastify__toast--error {
    background: #e74c3c;
}

.Toastify__toast--error {
    background: #f6f6f6;
    color: #e74c3c;
    border: solid #e74c3c;
}

.Toastify__progress-bar {
    opacity: 0.7;
    background-color: rgba(139, 139, 139, 0.7);
}

.Toastify__close-button {
    color: #8A8A8A;
}
